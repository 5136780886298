import {
    Component,
    Input,
} from '@angular/core';

/** Componente principal. */
@Component({
    selector: 'ndd-ng-data',
    template: require('./ndd-ng-data.component.html'),
})
export class NDDDataComponent {
    /** Disposição dos elementos. */
    @Input() public dataOrientation: 'column' | 'row' = 'row';
}
