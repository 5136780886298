import {
    Injectable,
    Optional,
} from '@angular/core';

import {
    INDDI18nServiceConfigResources,
    NDDI18nResourceService,
    NDDI18nServiceConfigResources,
} from 'ndd-ng-translation';

export interface INDDUploadI18nServiceConfigResources extends INDDI18nServiceConfigResources {
    selectFile?: string;
    restrictionExtension?: string;
    maxFileSize?: string;
    minFileSize?: string;
}

export class NDDUploadI18nServiceConfig implements NDDI18nServiceConfigResources {
    public i18n: boolean;
    public resources: INDDUploadI18nServiceConfigResources = {
        selectFile: 'Selecione ou arraste um arquivo...',
        restrictionExtension: 'Extensão de arquivo inválida',
        maxFileSize: 'Tamanho máximo inválido',
        minFileSize: 'Tamanho mínimo inválido',
    };
}

@Injectable()
export class NDDUploadI18nService extends NDDI18nResourceService {
    constructor(@Optional() config: NDDUploadI18nServiceConfig) {
        super(config, new NDDUploadI18nServiceConfig());
    }
}
