import { NgModule } from '@angular/core';

import { NDDFormFieldsetComponent } from './ndd-ng-form-fieldset.component';

@NgModule({
    declarations: [
        NDDFormFieldsetComponent,
    ],
    exports: [
        NDDFormFieldsetComponent,
    ],
})
export class NDDFormFieldSetModule { }
