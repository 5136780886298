import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanLoad,
} from '@angular/router';
import {
    Observable,
    of,
} from 'rxjs';
import { tap } from 'rxjs/operators';

import { SettingsService } from '../settings/settings.service';
import { AuthenticationStatusService } from './authentication-status.service';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticatedGuardService implements CanLoad, CanActivate {
    constructor(
        private authenticationService: AuthenticationService,
        private statusService: AuthenticationStatusService,
        private settingsService: SettingsService,
    ) { }

    public canLoad(): Observable<boolean> {
        if (this.statusService.loggedIn) {
            return of(true);
        }

        return this.authenticationService.getIsAlive()
            .pipe(tap((response: boolean) => {
                if (response) {
                    this.settingsService.getSettings().subscribe();
                } else {
                    this.authenticationService.logout();
                }
            }));
    }

    public canActivate(): Observable<boolean> {
        return this.canLoad();
    }
}
