export enum LocalStorageKey {
    Token = 'NDDReleaserWebToken',
    CurrentUser = 'NDDReleaserWebUser',
    CurrentLanguage = 'NDDReleaserWebCurrentLang',
    Resources = 'NDDReleaserWebResources',
    LastDocsPrinterSelectedId = 'NDDReleaserWebLastDocsPrinterSelectedId',
    AccountDisplayName = 'NDDReleaserAccountDisplayName',
    CorporateDisplayName = 'NDDReleaserCorporateDisplayName',
    PrintFinalityDisplayName = 'NDDReleaserPrintFinalityDisplayName',
    DocPrintingSummaryShowDetails = 'NDDReleaserDocPrintingSummaryShowDetails',
}
