import { NgModule } from '@angular/core';

import { UrlHandlerService } from './url-handler.service';

@NgModule({
    providers: [
        UrlHandlerService,
    ],
})
export class UrlHandlerModule { }
