import {
    EventEmitter,
    Inject,
    Injectable,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
    delay,
    take,
    takeUntil,
} from 'rxjs/operators';

import { NDDTranslationService } from 'ndd-ng-translation';

import { LocalStorageKey } from '../storage/local-storage.enum';
import { LocalStorageService } from '../storage/local-storage.service';
import { LanguageTypes } from './i18n-language-types.model';

@Injectable()
export class I18nConfigService {
    private static TRANSLATION_FETCH_DELAY: number = 500;

    public onFirstCurrentLangChanged: EventEmitter<any>;

    constructor(
        private translationService: NDDTranslationService,
        private localStorageService: LocalStorageService,
        @Inject(Window) private window: Window,
    ) {
        this.onFirstCurrentLangChanged = new EventEmitter<any>();
    }

    public setLanguage(): void {
        let navLang: LanguageTypes = <any>this.localStorageService.getValue(LocalStorageKey.CurrentLanguage);

        if (!navLang) {
            navLang = this.validateNavigatorLanguage(this.window.navigator.language.toLowerCase());
        }
        this.translationService.currentLang = <any>navLang;
    }

    public subscribeLanguageChange(ngUnsubscribe: Subject<void>): void {
        // Monitora primeira alteração de idioma.
        this.translationService.onFirstCurrentLangChanged
            .pipe(takeUntil(ngUnsubscribe))
            .subscribe((routeParam: string) => {
                this.translationService
                    .fetch(routeParam)
                    .pipe(
                        delay(I18nConfigService.TRANSLATION_FETCH_DELAY),
                        take(1),
                    )
                    .subscribe(() => this.onFirstCurrentLangChanged.emit());
            });

        // Monitora alterações de idioma.
        this.translationService.onCurrentLangChanged
            .pipe(takeUntil(ngUnsubscribe))
            .subscribe(() => {
                this.window.document.location.reload();
            });
    }

    private validateNavigatorLanguage(navLang: string): LanguageTypes {
        switch (navLang) {
            case LanguageTypes.English.toString():
                return LanguageTypes.English;
            case LanguageTypes.Spanish.toString():
                return LanguageTypes.Spanish;
            case LanguageTypes.Portuguese.toString():
                return LanguageTypes.Portuguese;
            case LanguageTypes.Basque.toString():
                return LanguageTypes.Basque;
            default:
                break;
        }

        if (navLang.includes('en')) {
            return LanguageTypes.English;
        } else if (navLang.includes('es')) {
            return LanguageTypes.Spanish;
        } else if (navLang.includes('pt')) {
            return LanguageTypes.Portuguese;
        }
        else if (navLang.includes('eu')) {
            return LanguageTypes.Basque;
        }

        return LanguageTypes.Portuguese;
    }
}
