import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AuthenticationTokenService } from './authentication-token.service';
import { IAuthProfile } from './shared/authentication-profile.model';
import { IAuthToken } from './shared/authentication-token.model';

@Injectable()
export class AuthenticationProfileService {
    public onRefreshAuthProfileData$: Subject<IAuthProfile> = new Subject<IAuthProfile>();

    constructor(private authTokenService: AuthenticationTokenService) { }

    public getAuthProfile(): IAuthProfile {
        const token: IAuthToken = this.authTokenService.token;
        const userdata: IAuthProfile = {
            email: token.eml,
            fullName: token.name,
            domain: token.dmn,
            userName: token.lgn,
        };

        this.onRefreshAuthProfileData$.next(userdata);

        return userdata;
    }
}
