import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import { NDDSidebarService } from '@ndk/ng-sidebar';
import { Subject } from 'rxjs';
import {
    take,
    takeUntil,
} from 'rxjs/operators';

import { NDDOffSidebarService } from 'ndd-ng-offsidebar';

import { IDocumentsDelegatedEnabledModel } from '../../features/documents-delegated/shared/documents-delegated-enabled.model';
import { DocumentsDelegatedService } from '../../features/documents-delegated/shared/documents-delegated.service';
import { IDocumentsSavedEnabledModel } from '../../features/documents-saved/shared/documents-saved-enabled.model';
import { DocumentsSavedService } from '../../features/documents-saved/shared/documents-saved.service';
import { AuthenticationProfileService } from '../authentication/authentication-profile.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { IAuthProfile } from '../authentication/shared/authentication-profile.model';
import { LayoutUserMenuContentComponent } from './user-menu/layout-user-menu-content.component';

@Component({
    selector: 'ndd-layout',
    template: require('./layout.component.html'),
})
export class LayoutComponent implements OnInit {
    public pinned: boolean = false;
    public fullName: string = '';
    public email: string = '';
    public showSavedDocuments: boolean;
    public showDelegatedDocuments: boolean;
    public navbarBreadcrumbHomeName: string = 'Releaser Web';
    public offSidebarUserMenuId: string = 'offsidebar-user-menu';
    public offSidebarHelpMenuId: string = 'offsidebar-menu-help';

    @ViewChild(LayoutUserMenuContentComponent, { static: false }) public userMenuContentComp: LayoutUserMenuContentComponent;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private documentsSavedService: DocumentsSavedService,
        private documentsDelegatedService: DocumentsDelegatedService,
        private sidebarService: NDDSidebarService,
        private authProfileService: AuthenticationProfileService,
        private authenticationService: AuthenticationService,
        private offsidebarService: NDDOffSidebarService,
        private cdr: ChangeDetectorRef,
    ) { }

    public ngOnInit(): void {
        this.refreshAuthProfileData(this.authProfileService.getAuthProfile());

        this.authProfileService.onRefreshAuthProfileData$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(this.refreshAuthProfileData.bind(this));

        this.documentsSavedService
            .getSavedDocumentsEnabled()
            .pipe(take(1))
            .subscribe((result: IDocumentsSavedEnabledModel) => {
                this.showSavedDocuments = result.enabled;
            });

        this.documentsDelegatedService
            .getDelegatedDocumentsEnabled()
            .pipe(take(1))
            .subscribe((result: IDocumentsDelegatedEnabledModel) => {
                this.showDelegatedDocuments = result.enabled;
            });

        this.cdr.detectChanges();
    }

    public onOffsidebarUserMenuClose(): void {
        this.userMenuContentComp.selectLanguage = false;
    }

    public onLogoutClick(): void {
        this.authenticationService.logout();
    }

    public onNavbarMenuUserDataClick(): void {
        this.offsidebarService.openOffSidebar(this.offSidebarUserMenuId);
    }

    public onNavbarMenuHelpClick(): void {
        this.offsidebarService.openOffSidebar(this.offSidebarHelpMenuId);
    }

    // TODO: Verificar se será usado futuramente.
    public onSidebarPin(pinned: boolean): void {
        this.pinned = pinned;
    }

    public onNavbarToggle(): void {
        this.sidebarService.toggleCollapse();
    }

    private refreshAuthProfileData(profile: IAuthProfile): void {
        this.fullName = profile.fullName;
        this.email = profile.email;
    }
}
