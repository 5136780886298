import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NDDButtonsModule } from '@ndk/ng-buttons';
import { NDDDialogModule } from '@ndk/ng-dialog';
import { NDDFormModule } from '@ndk/ng-form';
import { NDDGridModule } from '@ndk/ng-grid';
import { NDDLightGridModule } from '@ndk/ng-light-grid';
import {
    NDDOffSideScreenModule,
    NDDOffSideScreenService,
} from '@ndk/ng-off-side-screen';

import {
    NDDIntlModule,
    NDDIntlService,
} from 'ndd-ng-intl';
import { NDDSpinnerModule } from 'ndd-ng-spinner';
import { NDDTabsbarModule } from 'ndd-ng-tabsbar';
import { NDDTitlebarModule } from 'ndd-ng-titlebar';
import { NDDTranslationModule } from 'ndd-ng-translation';

import { AffinitiesService } from '../features/affinities/shared/affinities.service';
import { NDDDatasModule } from './components/ndd-ng-datas/ndd-ng-datas.module';
import { NDDFormFieldSetModule } from './components/ndd-ng-form-fieldset/ndd-ng-form-fieldset.module';
import { ReleaserStorageQuotaService } from './components/releaser-storage-quota/releaser-storage-quota.service';
import { OverrideIntlService } from './overrides/ng-intl/override-intl.service';
import { OverrideOffsideScreenService } from './overrides/ng-off-side-screen/override-off-side-screen.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NDDTranslationModule,
        NDDIntlModule,
        NDDButtonsModule,
        NDDSpinnerModule,
        NDDOffSideScreenModule,
        NDDFormModule,
        NDDTitlebarModule,
        NDDTabsbarModule,
        NDDDatasModule,
        NDDDialogModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NDDTranslationModule,
        NDDIntlModule,
        NDDButtonsModule,
        NDDSpinnerModule,
        NDDGridModule,
        NDDOffSideScreenModule,
        NDDFormModule,
        NDDTitlebarModule,
        NDDTabsbarModule,
        NDDDatasModule,
        NDDDialogModule,
        NDDFormFieldSetModule,
        NDDLightGridModule,
    ],
    providers: [
        // Overrides
        { provide: NDDOffSideScreenService, useClass: OverrideOffsideScreenService },
        { provide: NDDIntlService, useClass: OverrideIntlService },
        ReleaserStorageQuotaService,
        AffinitiesService,
    ],
})
export class SharedModule { }
