import { NgModule } from '@angular/core';

import { BytesConveterService } from './bytes-converter.service';

@NgModule({
    providers: [
        BytesConveterService,
    ],
})
export class BytesConverterModule { }
