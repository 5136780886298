import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NDDDialogModule } from '@ndk/ng-dialog';
import { NDDNavbarModule } from '@ndk/ng-navbar';
import { NDDSidebarModule } from '@ndk/ng-sidebar';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { NDDOffSidebarModule } from 'ndd-ng-offsidebar';
import { NDDTranslationService } from 'ndd-ng-translation';

import { DocumentsDelegatedService } from '../../features/documents-delegated/shared/documents-delegated.service';
import { DocumentsSavedService } from '../../features/documents-saved/shared/documents-saved.service';
import { SharedModule } from '../../shared/shared.module';
import { LayoutHelpMenuModule } from './help-menu/layout-help-menu.module';
import { LayoutComponent } from './layout.component';
import { LayoutUserMenuModule } from './user-menu/layout-user-menu.module';

@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        LayoutUserMenuModule,
        LayoutHelpMenuModule,
        NDDDialogModule,
        NDDNavbarModule,
        NDDSidebarModule,
        NDDOffSidebarModule,
        LoadingBarRouterModule,
    ],
    declarations: [LayoutComponent],
    providers: [
        NDDTranslationService,
        DocumentsSavedService,
        DocumentsDelegatedService,
    ],
})
export class LayoutModule { }
