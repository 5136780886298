import { HttpClient } from '@angular/common/http';
import {
    Inject,
    Injectable,
} from '@angular/core';
import {
    Params,
    Router,
} from '@angular/router';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    map,
    tap,
} from 'rxjs/operators';

import { ILoginIsAllowed } from '../../features/login/shared/login-is-allowed.model';
import {
    CORE_CONFIG_TOKEN,
    ICoreConfig,
} from '../core.config';
import { LocalStorageKey } from '../storage/local-storage.enum';
import { LocalStorageService } from '../storage/local-storage.service';
import { AuthenticationStatusService } from './authentication-status.service';
import { AuthenticationTokenService } from './authentication-token.service';
import { IAuthenticationSettings } from './shared/authentication-login-settings.model';
import { AuthenticationRoute } from './shared/authentication-route.enum';
import { IAuth } from './shared/authentication.model';
import { CredentialType } from './shared/authentication.model.enum';

@Injectable()
export class AuthenticationService {
    private apiUrl: string;
    private authenticationSettings: IAuthenticationSettings;
    private modulesStatus: ILoginIsAllowed;

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private authTokenService: AuthenticationTokenService,
        private statusService: AuthenticationStatusService,
        private router: Router,
        @Inject(CORE_CONFIG_TOKEN) private config: ICoreConfig,
    ) {
        this.apiUrl = this.config.apiEndpoint;
    }

    public login(data: IAuth, credentialType: CredentialType): Observable<void> {
        const body: object = {
            credentialType,
            username: data.username,
            domainName: data.domainName,
            password: data.password,
            pinCode: data.pinCode,
            securePinCode: data.securePinCode,
        };

        return this.http.post(`${ this.apiUrl }token`, body)
            .pipe(map((response: any) => this.handleAuthToken(true, <string>response.accessToken)));
    }

    public logout(returnUrl?: string): void {
        this.handleAuthToken(false);

        const queryParams: Params = { logout: true };

        if (returnUrl) {
            this.router.navigate([AuthenticationRoute.Login], { queryParams: { ...queryParams, returnUrl } });

            return;
        }

        this.router.navigate([AuthenticationRoute.Login], { queryParams });
    }

    public getSettings(force: boolean = false): Observable<IAuthenticationSettings> {
        if (!force && this.authenticationSettings) {
            return of(this.authenticationSettings);
        }

        return this.http.get<IAuthenticationSettings>(`${ this.apiUrl }api/authentication/settings`)
            .pipe(tap((x: IAuthenticationSettings) => this.authenticationSettings = x));
    }

    public getLoginIsAllowed(force: boolean = false): Observable<ILoginIsAllowed> {
        if (!force && this.modulesStatus) {
            return of(this.modulesStatus);
        }

        return this.http.get<ILoginIsAllowed>(`${ this.apiUrl }api/public/login-is-allowed`)
            .pipe(tap((x: ILoginIsAllowed) => this.modulesStatus = x));
    }

    public getIsAlive(): Observable<boolean> {
        return this.http.get(`${ this.apiUrl }api/public/is-alive`)
            .pipe(
                catchError(() => of(false)),
                map((response: boolean) => {
                    this.statusService.loggedIn = response;

                    return response;
                }),
            );
    }

    private handleAuthToken(login: boolean, token?: string): void {
        this.statusService.loggedIn = login;

        if (login) {
            this.authTokenService.createToken(token);
        } else {
            this.authTokenService.resetToken();
            this.localStorageService.deleteValue(LocalStorageKey.CurrentUser);
        }
    }
}
