export enum LanguageTypes {
    English = <any>'en-us',
    Spanish = <any>'es-es',
    Portuguese = <any>'pt-br',
    Basque = <any>'eu-es',

    Values = <any>[
        {
            id: English,
            name: 'English',
        },
        {
            id: Spanish,
            name: 'Español',
        },
        {
            id: Portuguese,
            name: 'Português',
        },
        {
            id: Basque,
            name: 'Euskera',
        },
    ],
}
