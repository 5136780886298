import { NgModule } from '@angular/core';

import { BreadcrumbHandlerService } from './breadcrumb-handler.service';

@NgModule({
    providers: [
        BreadcrumbHandlerService,
    ],
})
export class BreadcrumbHandlerModule { }
