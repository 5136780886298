import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NDDDialogGlobalConfigModule } from '@ndk/ng-dialog';
import { NDDFormGlobalConfigModule } from '@ndk/ng-form/config';
import { NDDOffSideScreenGlobalConfigModule } from '@ndk/ng-off-side-screen/config';
import * as toastr from 'toastr/toastr.js';

import {
    NDDTitlebarGlobalConfig,
    NDDTitlebarGlobalConfigModule,
} from 'ndd-ng-titlebar';

import { TOASTR_TOKEN } from '../core/logger/toastr.token';
import { SharedModule } from '../shared/shared.module';
import { AuthModule } from './authentication/authentication.module';
import { AuthenticationRoute } from './authentication/shared/authentication-route.enum';
import { BreadcrumbHandlerModule } from './breadcrumb-handler/breadcrumb-handler.module';
import { BytesConverterModule } from './bytes-converter/bytes-converter.module';
import { ErrorInterceptorModule } from './error-interceptor/error-interceptor.module';
import { GridConfigModule } from './grid-config/grid-config.module';
import { GridHandlerModule } from './grid-handler/grid-handler.module';
import { I18nModule } from './i18n/i18n.module';
import { LayoutModule } from './layout/layout.module';
import { LoggerModule } from './logger/logger.module';
import { PageReloadModule } from './page-reload/page-reload.module';
import { SettingsModule } from './settings/settings.module';
import { StorageModule } from './storage/storage.module';
import { UrlHandlerModule } from './url-handler/url-handler.module';

@NgModule({
    imports: [
        SharedModule,
        LayoutModule,
        StorageModule,
        I18nModule,
        AuthModule,
        PageReloadModule,
        HttpClientModule,
        ErrorInterceptorModule,
        GridConfigModule,
        GridHandlerModule,
        UrlHandlerModule,
        LoggerModule,
        BreadcrumbHandlerModule,
        SettingsModule,
        BytesConverterModule,
        NDDTitlebarGlobalConfigModule.forRoot(<NDDTitlebarGlobalConfig>{
            closeIcon: 'ndd-font ndd-font-close',
        }),
        NDDDialogGlobalConfigModule.forRoot({ i18n: true }),
        NDDOffSideScreenGlobalConfigModule.forRoot({ closeOnRouterChange: true }),
        NDDFormGlobalConfigModule.forRoot({
            unauthorizedRoute: AuthenticationRoute.NotAllowed,
            forbiddenRoute: AuthenticationRoute.Forbidden,
            serverErrorRoute: AuthenticationRoute.NotAllowed,
            useSpinner: false,
        }),
    ],
    exports: [PageReloadModule],
    providers: [
        // Tokens
        { provide: TOASTR_TOKEN, useValue: toastr },
    ],
})
export class CoreModule { }
