import { NgModule } from '@angular/core';
import {
    NDDGridGlobalConfig,
    NDDGridGlobalConfigModule,
} from '@ndk/ng-grid/config';

@NgModule({
    imports: [
        NDDGridGlobalConfigModule.forRoot(<NDDGridGlobalConfig>{
            i18n: true,
        }),
    ],
})

export class GridConfigModule { }
