import { Injectable } from '@angular/core';
import {
    INDDGrid,
    INDDGridColumn,
    INDDGridHeader,
    NDDGridBuilder,
    NDDGridBuilderService,
    NDDGridDirectionOrder,
    NDDGridHeaderBuilder,
} from '@ndk/ng-grid';

import { NDDTranslationService } from 'ndd-ng-translation';

import {
    IGridHandlerHeaderSettings,
    IGridHandlerSettings,
} from './grid-handler.model';

@Injectable()
export class GridHandlerService {
    private readonly pageSize: number = 100;
    private readonly defaultMinColumnResizableWidth: number = 50;

    constructor(
        private builder: NDDGridBuilderService,
        private translationService: NDDTranslationService,
    ) { }

    public configureOptions(settings: IGridHandlerSettings): INDDGrid {
        this.configureDefaultMinResizableWidth(settings.columns);

        const builder: NDDGridBuilder = this.builder.getBuilder()
            .identifier(settings.id)
            .withPageSize(settings.pageSize || this.pageSize)
            .sortable(true)
            .selectable(settings.singleSelectionMode || false)
            .columns(settings.columns)
            .sourceService(settings.service)
            .preserveWhitespaces(true)
            .allowCustomColumns(settings.allowCustomColumns || false)
            .resizable(true)
            .allowStoreResizedColumnWidths(true);

        if (settings.sortByField) {
            builder.sort(settings.sortByField, settings.sortDir || NDDGridDirectionOrder.ASC);
        }

        return builder.build();
    }

    public configureHeaderOptions(settings: IGridHandlerHeaderSettings): INDDGridHeader {
        const builder: NDDGridHeaderBuilder = this.builder.getHeaderBuilder()
            .withDropdownText('360RActions')
            .withCancelButton('360RCancel')
            .withEditColumnDescription('360REditColumnsDescription')
            .i18n(true);

        if (settings.actions) {
            builder.actions(settings.actions);
        }

        if (settings.searchbar) {
            builder.filter(settings.searchbar.options);
        }

        if (settings.headerInfo) {
            builder.withHeaderInfo(settings.headerInfo);
        }

        const options: INDDGridHeader = builder.build();

        const placeholder: string = settings.searchbar.placeholderResource;
        if (placeholder) {
            options.filter.searchbarPlaceholder = this.translationService.instant(placeholder);
        }

        if (settings.filterAdvanced) {
            if (!options.filter) { options.filter = {}; }
            options.filter.advanced = settings.filterAdvanced;
        }

        return options;
    }

    /** Define valor padrão para a propriedade `minResizableWidth` de cada coluna com a propriedade não definida. */
    private configureDefaultMinResizableWidth(columns: INDDGridColumn[]): void {
        columns.forEach((x: INDDGridColumn) => {
            if (!!x.icons || x.minResizableWidth !== undefined) { return; }

            x.minResizableWidth = this.defaultMinColumnResizableWidth;
        });
    }
}
