import { Injectable } from '@angular/core';

import { IFormattedSize } from './formatted-size.model';

@Injectable()
export class BytesConveterService {
    private sizeUnitSymbols: string[] = ['B', 'KB', 'MB', 'GB'];

    public formatDiskSize(rawSize: number): IFormattedSize {
        let auxSize: number = rawSize;
        let sizeUnitSymbolIndex: number = 0;
        const byteDelimiter: number = 1024;

        while (auxSize >= byteDelimiter && sizeUnitSymbolIndex < this.sizeUnitSymbols.length - 1) {
            auxSize = auxSize / byteDelimiter;
            sizeUnitSymbolIndex++;
        }

        const decimalPlacesCount: number = 2;

        const formattedSize: IFormattedSize = {
            amountOfBytes: auxSize,
            unitSymbol: this.sizeUnitSymbols[sizeUnitSymbolIndex],
            decimalPlaces: auxSize % 1 === 0 ? 0 : decimalPlacesCount,
        };

        return formattedSize;
    }
}
