import { HttpClient } from '@angular/common/http';
import {
    Inject,
    Injectable,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    CORE_CONFIG_TOKEN,
    ICoreConfig,
} from '../../../core.config';
import { ILayoutHelpMenuManual } from './layout-help-menu.model';

@Injectable()
export class LayoutHelpMenuService {
    private apiUrl: string;
    private manualUrlLanguageToken: string = '[language]';

    constructor(
        @Inject(CORE_CONFIG_TOKEN) config: ICoreConfig,
        private http: HttpClient,
    ) {
        this.apiUrl = `${ config.apiEndpoint }api/manual`;
    }

    /**
     * Retorna o endereço do manual.
     */
    public getManualUrl(language: string): Observable<string> {
        return this.http
            .get<ILayoutHelpMenuManual>(`${ this.apiUrl }/address`)
            .pipe(map(this.handleManualUrl.bind(this, language)));
    }

    /**
     * Substitui o token "[language]" do endereço do manual para o idioma desejado.
     *
     * Exemplo: https://resources.nddprint.com/docs/help/360/[language]/ReleaserWeb/index.html
     */
    private handleManualUrl(language: string, manual: ILayoutHelpMenuManual): string {
        return manual.url.replace(this.manualUrlLanguageToken, this.manualLanguage(language));
    }

    private manualLanguage(language: string): string {
        switch (language.toLowerCase()) {
            case 'pt-br':
            case 'es-es':
            case 'en-us':
                return language;
            default:
                return 'en-US';
        }
    }
}
