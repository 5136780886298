import { InjectionToken } from '@angular/core';

export const CORE_CONFIG_TOKEN: InjectionToken<ICoreConfig> = new InjectionToken<ICoreConfig>('core.config');

export interface ICoreConfig {
    apiEndpoint: string;
    apiResourcesEndpoint: string;
    client_id: string,
}

export const CORE_CONFIG: ICoreConfig = {
    apiEndpoint: handleLastUrlSlash(NDD_ENV.apiUrl),
    apiResourcesEndpoint: `${ handleLastUrlSlash(NDD_ENV.apiUrl) }api/translation?language=`,
    client_id: '3b9a77fb35a54e40815f4fa8641234c5',
};

// tslint:disable-next-line:only-arrow-functions
function handleLastUrlSlash(url: string): string {
    if (!url) {
        throw new Error('ApiUrl is null or empty.');
    }

    return url.endsWith('/') ? url : `${ url }/`;
}
