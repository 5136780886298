import {
    Component,
    Input,
} from '@angular/core';

/** Componente fieldset. */
@Component({
    selector: 'ndd-ng-form-fieldset',
    template: require('./ndd-ng-form-fieldset.component.html'),
})
export class NDDFormFieldsetComponent {
    @Input() public controlId: string;
    @Input() public controlLegend: string;
    @Input() public controlDescription: string;
    @Input() public controlDisabled?: boolean;
}
