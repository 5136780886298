import { HttpClient } from '@angular/common/http';
import {
    Inject,
    Injectable,
} from '@angular/core';
import { Observable } from 'rxjs';

import {
    CORE_CONFIG_TOKEN,
    ICoreConfig,
} from '../../../core/core.config';
import { IDocumentsSavedEnabledModel } from './documents-saved-enabled.model';

@Injectable()
export class DocumentsSavedService {
    private apiUrl: string;

    constructor(
        @Inject(CORE_CONFIG_TOKEN) config: ICoreConfig,
        private http: HttpClient,
    ) {
        this.apiUrl = `${ config.apiEndpoint }api/documents`;
    }

    public getSavedDocumentsEnabled(): Observable<IDocumentsSavedEnabledModel> {
        return this.http.get<IDocumentsSavedEnabledModel>(`${ this.apiUrl }/saved-documents-enabled`);
    }
}
