import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { LocalStorageKey } from '../storage/local-storage.enum';
import { LocalStorageService } from '../storage/local-storage.service';
import { IAuthToken } from './shared/authentication-token.model';

@Injectable()
export class AuthenticationTokenService {
    private jwtHelper: JwtHelperService = new JwtHelperService();
    private currentUser: any = JSON.parse(this.localStorage.getValue(LocalStorageKey.CurrentUser));

    constructor(private localStorage: LocalStorageService) { }

    public get token(): IAuthToken {
        if (!this.currentUser) {
            const t: string = this.localStorage.getValue(LocalStorageKey.Token);

            if (t) {
                this.currentUser = this.jwtHelper.decodeToken(t);
            }
        }

        return this.currentUser || <IAuthToken>{};
    }

    public createToken(value: string): void {
        /* Armazena o token no Local Storage */
        this.localStorage.setValue(LocalStorageKey.Token, value);
        /* Armazena o usuário atual no Local Storage */
        this.currentUser = this.jwtHelper.decodeToken(value);
        this.localStorage.setValue(LocalStorageKey.CurrentUser, JSON.stringify(this.currentUser));
    }

    public resetToken(): void {
        this.currentUser = null;
        this.localStorage.deleteValue(LocalStorageKey.Token);
    }
}
