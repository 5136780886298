import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

/** Componente agrupador. */
@Component({
    selector: 'ndd-ng-data-group',
    template: require('./ndd-ng-data-group.component.html'),
})
export class NDDDataGroupComponent implements OnInit {
    /** Título do grupo. */
    @Input() public dataTitle: string;
    /** Descrição do grupo. */
    @Input() public dataDescription?: string;

    public ngOnInit(): void {
        if (!this.dataTitle) {
            throw new Error('É necessário definir a propriedade `dataTitle`.');
        }
    }
}
