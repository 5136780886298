import { NgModule } from '@angular/core';

import { GridHandlerService } from './grid-handler.service';

@NgModule({
    providers: [
        GridHandlerService,
    ],
})
export class GridHandlerModule { }
