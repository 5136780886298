import { HttpClient } from '@angular/common/http';
import {
    Inject,
    Injectable,
} from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NDDTranslationService } from 'ndd-ng-translation';

import {
    CORE_CONFIG_TOKEN,
    ICoreConfig,
} from '../core.config';
import { LocalStorageKey } from '../storage/local-storage.enum';
import { LocalStorageService } from '../storage/local-storage.service';
import { ISettingsModel } from './settings.model';

@Injectable()
export class SettingsService {
    private readonly accountDisplayNameReplacement: string = '360RAccount';
    private readonly printFinalityDisplayNameReplacement: string = '360RFinality';
    private readonly corporateDisplayNameReplacement: string = '360RCorporate';
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        @Inject(CORE_CONFIG_TOKEN) private config: ICoreConfig,
        private translationService: NDDTranslationService,
        private storageService: LocalStorageService,
    ) {
        this.apiUrl = this.config.apiEndpoint;
    }

    public getSettings(): Observable<ISettingsModel> {
        return this.http.get<ISettingsModel>(`${ this.apiUrl }api/settings`)
            .pipe(tap((settings: ISettingsModel) => this.handleSettings(settings)));
    }

    /**
     * Formata o nome de exibição para o termo "conta".
     * @param usePlaceholder Concatenar o nome de exibição em outra resource.
     * @param resourceToInsertDisplayName Resource para concatenar o nome de exibição.
     */
    public accountDisplayName(usePlaceholder: boolean = false, resourceToInsertDisplayName?: string): string {
        const name: string = this.storageService.getValue(LocalStorageKey.AccountDisplayName);

        return this.formatDisplayName(name, this.accountDisplayNameReplacement, usePlaceholder, resourceToInsertDisplayName);
    }

    /**
     * Formata o nome de exibição para o termo "corporativa".
     * @param usePlaceholder Concatenar o nome de exibição em outra resource.
     * @param resourceToInsertDisplayName Resource para concatenar o nome de exibição.
     */
    public corporateDisplayName(usePlaceholder: boolean = false, resourceToInsertDisplayName?: string): string {
        const name: string = this.storageService.getValue(LocalStorageKey.CorporateDisplayName);

        return this.formatDisplayName(name, this.corporateDisplayNameReplacement, usePlaceholder, resourceToInsertDisplayName);
    }

    /**
     * Formata o nome de exibição para o termo "finalidade" (finalidade de impressão).
     * @param usePlaceholder Concatenar o nome de exibição em outra resource.
     * @param resourceToInsertDisplayName Resource para concatenar o nome de exibição.
     */
    public printFinalityDisplayName(usePlaceholder: boolean = false, resourceToInsertDisplayName?: string): string {
        const name: string = this.storageService.getValue(LocalStorageKey.PrintFinalityDisplayName);

        return this.formatDisplayName(name, this.printFinalityDisplayNameReplacement, usePlaceholder, resourceToInsertDisplayName);
    }

    private formatDisplayName(
        displayName: string,
        displayNameReplacement: string,
        usePlaceholder: boolean = false,
        resourceToInsertDisplayName?: string): string {
        let name: string = displayName;

        if (name.startsWith('@@')) {
            name = this.translationService.instant(displayNameReplacement);
        }

        if (usePlaceholder) {
            name = this.translationService.instant(resourceToInsertDisplayName, [name]);
        }

        return name;
    }

    private handleSettings(settings: ISettingsModel): void {
        this.storageService.setValue(LocalStorageKey.AccountDisplayName, settings.accountDisplayName);
        this.storageService.setValue(LocalStorageKey.CorporateDisplayName, settings.corporateDisplayName);
        this.storageService.setValue(LocalStorageKey.PrintFinalityDisplayName, settings.printFinalityDisplayName);
    }
}
