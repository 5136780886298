import { HttpClient } from '@angular/common/http';
import {
    Inject,
    Injectable,
} from '@angular/core';
import { Observable } from 'rxjs';

import {
    CORE_CONFIG_TOKEN,
    ICoreConfig,
} from '../../../core/core.config';
import { IReleaserStorageModel } from '../../../features/home/shared/releaser-storage.model';

@Injectable()
export class ReleaserStorageQuotaService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        @Inject(CORE_CONFIG_TOKEN) private config: ICoreConfig,
    ) {
        this.apiUrl = this.config.apiEndpoint;
    }

    public getStorage(): Observable<IReleaserStorageModel> {
        return this.http.get<IReleaserStorageModel>(`${ this.apiUrl }api/releaser-storage-quota`);
    }
}
