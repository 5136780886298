import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NDDTranslationModule } from 'ndd-ng-translation';

import { LayoutHelpMenuContentComponent } from './layout-help-menu.content.component';
import { LayoutHelpMenuService } from './shared/layout-help-menu.service';

@NgModule({
    imports: [
        CommonModule,
        NDDTranslationModule,
    ],
    exports: [
        LayoutHelpMenuContentComponent,
    ],
    declarations: [
        LayoutHelpMenuContentComponent,
    ],
    providers: [
        LayoutHelpMenuService,
    ],
})
export class LayoutHelpMenuModule { }
