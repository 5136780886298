import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NDDIntlModule } from 'ndd-ng-intl';

import { NDDDataGroupComponent } from './ndd-ng-data-group/ndd-ng-data-group.component';
import { NDDDataItemComponent } from './ndd-ng-data-item/ndd-ng-data-item.component';
import { NDDDataComponent } from './ndd-ng-data/ndd-ng-data.component';

@NgModule({
    imports: [
        CommonModule,
        NDDIntlModule,
    ],
    exports: [
        NDDDataComponent,
        NDDDataItemComponent,
        NDDDataGroupComponent,
    ],
    declarations: [
        NDDDataComponent,
        NDDDataItemComponent,
        NDDDataGroupComponent,
    ],
})
export class NDDDatasModule { }
