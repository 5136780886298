import {
    Inject,
    Injectable,
    Type,
} from '@angular/core';
import {
    AbstractOffSideScreen,
    NDDOffSideScreenService,
} from '@ndk/ng-off-side-screen';
import { Observable } from 'rxjs';

import { NDDInjectionService } from 'ndd-ng-core';

/** Classe apenas para override, usar `NDDOffSideScreenService` invés. */
@Injectable()
export class OverrideOffsideScreenService extends NDDOffSideScreenService {
    private readonly waitingTimeEnsureRenderedGrid: number = 500;

    constructor(
        injectionService: NDDInjectionService,
        @Inject(Window) private window: Window,
    ) {
        super(injectionService);
    }

    public open(componentType: Type<AbstractOffSideScreen>, parameters?: any): Observable<any> {
        // Executa método original.
        const result: Observable<any> = super.open(componentType, parameters);

        this.window.setTimeout(() => {
            const comp: HTMLElement = <HTMLElement>this.window.document.querySelector('.off-side-screen');
            if (!comp) { return; }

            const gridElemExists: boolean = !!comp.getElementsByTagName('ndd-ng-grid')[0];
            const treeGridElemExists: boolean = !!comp.getElementsByTagName('ndd-ng-tree-grid')[0];

            // Verifica se existe grid ou tree-grid para, assim, fixar o tamanho do offside-screen.
            if (!!gridElemExists || !!treeGridElemExists) {
                const compContent: HTMLElement = <HTMLElement>comp.getElementsByClassName('off-side-screen__content')[0];
                compContent.style.minWidth = 'calc(100% - 220px)';
            }
        }, this.waitingTimeEnsureRenderedGrid);

        return result;
    }
}
