import './main.scss';
import './app/shared/fonts/ndd.font';
/* Intl */
// Alemanha
import '@progress/kendo-angular-intl/locales/de/all';
// Basco
import '@progress/kendo-angular-intl/locales/eu/all';
// Brasil
import '@progress/kendo-angular-intl/locales/pt/all';
// Espanha
import '@progress/kendo-angular-intl/locales/es/all';
// Estados Unidos
import '@progress/kendo-angular-intl/locales/en/all';
// França
import '@progress/kendo-angular-intl/locales/fr/all';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

((): void => {
    if (ENV === 'production') {
        enableProdMode();
    }

    platformBrowserDynamic().bootstrapModule(AppModule);
})();
