import { Injectable } from '@angular/core';

@Injectable()
export class AuthenticationStatusService {
    private status: boolean = false;

    public get loggedIn(): boolean {
        return this.status;
    }

    public set loggedIn(status: boolean) {
        this.status = status;
    }
}
