import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { NDDDialogService } from '@ndk/ng-dialog';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { NDDTranslationService } from 'ndd-ng-translation';

import { LanguageTypes } from '../../i18n/i18n-language-types.model';

@Component({
    template: require('./layout-user-menu-content.component.html'),
    selector: 'ndd-layout-user-menu-content',
})
export class LayoutUserMenuContentComponent implements OnInit, OnDestroy {
    public showActionMySettings: boolean;
    public initialCurrentLang: string;
    public languages: LanguageTypes;
    public selectLanguage: boolean = false;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private translationService: NDDTranslationService,
        private dialogService: NDDDialogService,
    ) { }

    public ngOnInit(): void {
        this.languages = LanguageTypes.Values;
        this.initialCurrentLang = this.getLanguageName(this.translationService.currentLang);
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public changeLanguage(): void {
        this.selectLanguage = true;
    }

    public setLanguage(lang: LanguageTypes): void {
        if (this.translationService.currentLang === <any>lang) {
            this.dialogService.alert({ message: '360RLanguageSelectedNoHasChanged' });
            this.selectLanguage = false;
        } else {
            this.dialogService
                .confirm({ message: '360RConfirmLanguageChange' })
                .pipe(take(1))
                .subscribe((response: boolean) => {
                    if (response) {
                        this.translationService.currentLang = <any>lang;
                        this.selectLanguage = false;
                    }
                });
        }
    }

    private getLanguageName(lang: string): string {
        const language: any = (<any>LanguageTypes.Values)
            .find((value: any) => value.id === lang);

        return language.name;
    }
}
