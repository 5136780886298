import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { ILoginIsAllowed } from './login-is-allowed.model';

@Injectable()
export class LoginIsAllowedResolverService implements Resolve<ILoginIsAllowed> {
    constructor(private authenticationService: AuthenticationService) { }

    public resolve(): Observable<ILoginIsAllowed> {
        return this.authenticationService.getLoginIsAllowed();
    }
}
