import { NgModule } from '@angular/core';

import { LoggerService } from './logger.service';

@NgModule({
    imports: [
    ],
    declarations: [
    ],
    providers: [
        LoggerService,
    ],
})
export class LoggerModule { }
