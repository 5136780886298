import { NDDButtonsI18nServiceConfig } from '@ndk/ng-buttons';
import { NDDDialogI18nServiceConfig } from '@ndk/ng-dialog';
import { NDDFormI18nServiceConfig } from '@ndk/ng-form';
import { NDDGridI18nServiceConfig } from '@ndk/ng-grid';
import { NDDLightGridI18nServiceConfig } from '@ndk/ng-light-grid';

import { NDDIntlServiceConfig } from 'ndd-ng-intl';
import { NDDTranslationConfig } from 'ndd-ng-translation';

import { NDDUploadI18nServiceConfig } from '../../shared/components/ndd-ng-upload/shared/ndd-ng-upload-i18n.service';
import { CORE_CONFIG } from '../core.config';
import { LocalStorageKey } from '../storage/local-storage.enum';

export const nddTranslationConfigValue: NDDTranslationConfig = {
    api: CORE_CONFIG.apiResourcesEndpoint,
    keys: {
        currentLangKey: LocalStorageKey.CurrentLanguage,
        resourcesKey: LocalStorageKey.Resources,
    },
};

export const nddLightGridI18nServiceConfigValue: NDDLightGridI18nServiceConfig = {
    i18n: true,
    resources: {
        gridNoRecords: '360RKendoGridNoRecords',
        columnLinkGoTo: '360RGoTo',
        footerTotalItem: '360RItemLowerCase',
        footerTotalItems: '360RKendoGridPagerItems',
    },
};

export const nddKendoTranslationGridConfigValue: any = {
    GRID: {
        i18n: true,
        resources: {
            noRecords: '360RKendoGridNoRecords',
            pagerPage: '360RKendoGridPagerPage',
            pagerOf: '360RKendoGridPagerOf',
            pagerItems: '360RKendoGridPagerItems',
            pagerItemsPerPage: '360RKendoGridPagerItemsPerPage',
        },
    },
};

export const nddDialogI18nServiceConfigValue: NDDDialogI18nServiceConfig = {
    i18n: true,
    resources: {
        alertTitle: '360RWarning',
        alertButton: '360ROk',
        confirmationTitle: '360RConfirmation',
        confirmationYesButton: '360RYes',
        confirmationNoButton: '360RNo',
    },
};

export const nddButtonsI18nServiceConfigValue: NDDButtonsI18nServiceConfig = {
    i18n: true,
    resources: {
        btnCancel: '360RCancel',
        btnSave: '360RSave',
        btnSaveAndCreate: '360RSaveCreate',
        btnSaveAndOpen: '360RSaveOpen',
    },
};

export const nddGridI18nServiceConfigValue: NDDGridI18nServiceConfig = {
    i18n: true,
    resources: {
        columnLinkGoTo: '360RGoTo',
        searchbarPlaceholder: '360RSearch',
        advancedButton: '360RFilters',
        advancedHeaderActionApply: '360RApply',
        advancedHeaderActionClear: '360RClear',
        advancedHeaderActionHide: '360RHide',
        advancedHeaderActionClose: '360RClose',
        advancedColumnField: '360RField',
        advancedColumnOperator: '360ROperator',
        advancedColumnValue: '360RValue',
        advancedFooterActionNewClause: '360RNewClause',
        advancedOptionLogicalAnd: '360RAnd',
        advancedOptionLogicalOr: '360ROr',
        advancedOptionOperatorEquals: '360REquals',
        advancedOptionOperatorContains: '360RContains',
        advancedOptionOperatorStartsWith: '360RStartsWith',
        advancedOptionOperatorEndsWith: '360REndsWith',
        advancedOptionOperatorGreaterThan: '360RGreaterThan',
        advancedOptionOperatorGreaterThanOrEqual: '360RGreaterThanOrEqual',
        advancedOptionOperatorLessThan: '360RLessThan',
        advancedOptionOperatorLessThanOrEqual: '360RLessThanOrEqual',
        advancedOptionOperatorNotEquals: '360RNotEquals',
        advancedOptionOperatorNotContains: '360RNotContains',
        advancedOptionOperatorNotStartsWith: '360RNotStartsWith',
        advancedOptionOperatorNotEndsWith: '360RNotEndsWith',
        editColumnsButton: '360REditColumns',
        editColumnHeaderActionSave: '360RSave',
        editColumnHeaderRestoreDefault: '360RRestoreDefault',
        editColumnOffSideScreenTitle: '360REditColumns',
        editColumnMessageError: '360RSelectAtLeastOneColumn',
    },
};

export const nddFormI18nServiceConfigValue: NDDFormI18nServiceConfig = {
    i18n: true,
    resources: {
        requiredField: '360RRequiredField',
        maxLength: '360RInvalidMaxLength',
        minLength: '360RInvalidMinLength',
        incorrectField: '360RInvalidPattern',
        invalidEmail: '360RInvalidEmail',
        invalidMaxValue: '360RInvalidMaxVal',
        invalidMinValue: '360RInvalidMinVal',
        alertUnsavedData: '360RQuestionDiscardUnsavedData',
    },
};

export const nddIntlServiceConfigValue: NDDIntlServiceConfig = {
    keys: {
        cultureKey: LocalStorageKey.CurrentLanguage,
        currencyCultureKey: '',
    },
};

export const nddUploadI18nServiceConfigValue: NDDUploadI18nServiceConfig = {
    i18n: true,
    resources: {
        selectFile: '360RSelectOrDragFile',
        restrictionExtension: '360RRestrictionExtensionMsg',
        maxFileSize: '360RMaxFileSizeMsg',
    },
};
