import {
    Inject,
    Injectable,
} from '@angular/core';

import { IToastr } from './toastr.model';
import { TOASTR_TOKEN } from './toastr.token';

const TOASTR_CONFIG: any = {
    timeOut: 5000,
    positionClass: 'toast-bottom-right',
};

const LOGGING_TYPE: any = {
    SUCCESS: ['log', 'success'],
    INFO: ['info', 'info'],
    WARNING: ['warn', 'warning'],
    ERROR: ['error', 'error'],
};

@Injectable()
export class LoggerService {
    constructor(@Inject(TOASTR_TOKEN) private toastr: IToastr) { }

    public success(showToastr: boolean, msg: any[], title?: string): void {
        this.write(LOGGING_TYPE.SUCCESS, showToastr, msg, title);
    }

    public error(showToastr: boolean, msg: any[], title?: string): void {
        this.write(LOGGING_TYPE.ERROR, showToastr, msg, title);
    }

    public info(showToastr: boolean, msg: any[], title?: string): void {
        this.write(LOGGING_TYPE.INFO, showToastr, msg, title);
    }

    public warning(showToastr: boolean, msg: any[], title?: string): void {
        this.write(LOGGING_TYPE.WARNING, showToastr, msg, title);
    }

    private write(type: string[], showToastr: boolean, msg: any[], title?: string): void {
        if (Array.isArray(msg) && msg.length > 0 && msg.join('').trim().length > 0) {
            if (title) {
                // tslint:disable-next-line:no-console
                console.group(title);
            }

            msg.forEach((value: string) => {
                console[type[0]](value);
            });

            if (showToastr) {
                this.toastr[type[1]](msg.join('<br>'), title, TOASTR_CONFIG);
            }

            if (title) {
                // tslint:disable-next-line:no-console
                console.groupEnd();
            }
        }
    }
}
