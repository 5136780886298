import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    INDDIntlFormat,
    NDDIntlService,
} from 'ndd-ng-intl';

/** Componente do item de dados. */
@Component({
    selector: 'ndd-ng-data-item',
    template: require('./ndd-ng-data-item.component.html'),
})
export class NDDDataItemComponent implements OnInit {
    /** Id do item. */
    @Input() public dataId?: string;
    /* Nome do termo exibido (label). */
    @Input() public dataTerm: string;
    /** Definição do item (valor a ser exibido). */
    @Input() public set dataDefinition(value: string | number | Date) {
        this.dataDefinitionAux = value;
        this.setDataDefinition(value);
    }
    /** Legenda da definição do item. */
    @Input() public dataDefinitionLegend: string | string[];

    /** Classe da fonte (ícone) a ser exibida junto com o valor. */
    @Input() public dataDefinitionIconClass?: string;
    /** Manter formatação multilinha ('\n') na definição do item. */
    @Input() public dataDefinitionMultiline: boolean = false;
    /** Padrão de formatação do valor. */
    @Input() public dataFormat?: INDDIntlFormat;
    /** Prefixo para o valor. */
    @Input() public dataPrefix?: string;
    /** Sufixo para o valor. */
    @Input() public dataPostfix?: string;

    /* Dado para exibição. */
    public data: string;
    /* Legendas para exibição. */
    public legends: string[];

    private dataDefinitionAux: string | number | Date;

    constructor(private intl: NDDIntlService) { }

    public ngOnInit(): void {
        this.setDataDefinition(this.dataDefinitionAux);
        this.setDataLegends(this.dataDefinitionLegend);
    }

    public setDataLegends(data: string | string[]): void {
        if (typeof data === 'string') {
            this.legends = [data];

            return;
        }

        this.legends = data;
    }

    public setDataDefinition(value: string | number | Date): void {
        if (this.dataFormat) {
            this.dataDefinitionAux = this.intl.format(<any>value, this.dataFormat.format, this.dataFormat.culture);
        }

        if (this.dataPrefix && this.dataPostfix) {
            this.data = `${ this.dataPrefix } ${ this.dataDefinitionAux } ${ this.dataPostfix }`;
        } else if (this.dataPrefix) {
            this.data = `${ this.dataPrefix } ${ this.dataDefinitionAux }`;
        } else if (this.dataPostfix) {
            this.data = `${ this.dataDefinitionAux } ${ this.dataPostfix }`;
        } else {
            this.data = this.dataDefinitionAux !== undefined && this.dataDefinitionAux !== null && this.dataDefinitionAux !== ''
                ? this.dataDefinitionAux.toString()
                : '';
        }
    }
}
