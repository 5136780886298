import { HttpClient } from '@angular/common/http';
import {
    Inject,
    Injectable,
} from '@angular/core';
import { Observable } from 'rxjs';
import { IDocumentDelegationsAddCommand } from 'src/app/shared/components/documents/shared/document.model';

import {
    CORE_CONFIG_TOKEN,
    ICoreConfig,
} from '../../../core/core.config';
import { IDocumentsDelegatedEnabledModel } from './documents-delegated-enabled.model';

@Injectable()
export class DocumentsDelegatedService {
    private apiUrl: string;

    constructor(
        @Inject(CORE_CONFIG_TOKEN) config: ICoreConfig,
        private http: HttpClient,
    ) {
        this.apiUrl = `${ config.apiEndpoint }api/documents`;
    }

    public getDelegatedDocumentsEnabled(): Observable<IDocumentsDelegatedEnabledModel> {
        return this.http.get<IDocumentsDelegatedEnabledModel>(`${ this.apiUrl }/delegated-documents-enabled`);
    }

    public addDelegation(delegations: IDocumentDelegationsAddCommand): Observable<boolean> {
        return this.http.post<boolean>(`${ this.apiUrl }/add-delegations`, delegations);
    }
}
