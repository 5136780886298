// tslint:disable:typedef
// tslint:disable:arrow-parens
import { NgModule } from '@angular/core';
import {
    RouterModule,
    Routes,
} from '@angular/router';

import { AuthenticatedGuardService } from './core/authentication/authenticated-guard.service';
import { LayoutComponent } from './core/layout/layout.component';

const appRoutes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./features/login/login.module').then(x => x.LoginModule),
    },
    {
        path: 'page-not-found',
        loadChildren: () => import('./features/error-pages/page-not-found/page-not-found.module').then(x => x.PageNotFoundModule),
    },
    {
        path: 'page-forbidden',
        loadChildren: () => import('./features/error-pages/page-forbidden/page-forbidden.module').then(x => x.PageForbiddenModule),
    },
    {
        path: '',
        component: LayoutComponent,
        canLoad: [AuthenticatedGuardService],
        canActivate: [AuthenticatedGuardService],
        children: [
            // Rota que redireciona para a home quando não tem rota na url
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full',
            },
            // Rota inicial do app
            {
                path: 'home',
                loadChildren: () => import('./features/home/home.module').then(x => x.HomeModule),
                data: {
                    breadcrumbOptions: {
                        breadcrumbLabel: '360RStart',
                    },
                },
            },
            {
                path: 'saved-documents',
                loadChildren: () => import('./features/documents-saved/documents-saved.module').then(x => x.DocumentsSavedModule),
                data: {
                    breadcrumbOptions: {
                        breadcrumbLabel: '360RSaved',
                    },
                },
            },
            {
                path: 'pending-documents',
                loadChildren: () => import('./features/documents-pending/documents-pending.module').then(x => x.DocumentsPendingModule),
                data: {
                    breadcrumbOptions: {
                        breadcrumbLabel: '360RPending',
                    },
                },
            },
            {
                path: 'delegated-documents',
                loadChildren: () => import('./features/documents-delegated/documents-delegated.module').then(x => x.DocumentsDelegatedModule),
                data: {
                    breadcrumbOptions: {
                        breadcrumbLabel: '360RDelegated',
                    },
                },
            },
            {
                path: 'affinities',
                loadChildren: () => import('./features/affinities/affinities.module').then(x => x.AffinitiesModule),
                data: {
                    breadcrumbOptions: {
                        breadcrumbLabel: '360RAffinities',
                    },
                },
            },
        ],
    },
    { path: '**', redirectTo: 'page-not-found', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {
        paramsInheritanceStrategy: 'always',
    })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
