import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LoginIsAllowedResolverService } from '../../features/login/shared/login-is-allowed-resolver.service';
import { SharedModule } from '../../shared/shared.module';
import {
    CORE_CONFIG,
    CORE_CONFIG_TOKEN,
} from '../core.config';
import { StorageModule } from '../storage/storage.module';
import { AuthInterceptor } from './auth-interceptor.service';
import { AuthenticatedGuardService } from './authenticated-guard.service';
import { AuthenticationProfileService } from './authentication-profile.service';
import { AuthenticationStatusService } from './authentication-status.service';
import { AuthenticationTokenService } from './authentication-token.service';
import { AuthenticationService } from './authentication.service';

@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        StorageModule,
    ],
    exports: [],
    declarations: [],
    providers: [
        { provide: CORE_CONFIG_TOKEN, useValue: CORE_CONFIG },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        AuthenticationProfileService,
        AuthenticationStatusService,
        AuthenticationTokenService,
        AuthenticationService,
        AuthenticatedGuardService,
        LoginIsAllowedResolverService,
    ],
})
export class AuthModule { }
