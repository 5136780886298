import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    Observable,
    throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';
import { AuthenticationRoute } from '../authentication/shared/authentication-route.enum';
import { ErrorInterceptorParams } from './error-interceptor-params';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private static HTTP_STATUS_401: number = 401;
    private static HTTP_STATUS_403: number = 403;
    private static HTTP_STATUS_404: number = 404;
    private static HTTP_STATUS_503: number = 503;
    private readonly notFoundRoute: string = AuthenticationRoute.NotFound;
    private readonly forbiddenRoute: string = AuthenticationRoute.Forbidden;
    private readonly notAllowedRoute: string = AuthenticationRoute.NotAllowed;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const errorHandling: boolean = request.params instanceof ErrorInterceptorParams ? request.params.options.errorHandling : true;

        return next
            .handle(request)
            .pipe(
                catchError((response: any) => {
                    if (response instanceof HttpErrorResponse && errorHandling) {
                        switch (response.status) {
                            case (ErrorInterceptor.HTTP_STATUS_401): {
                                this.handleUnauthorized();
                                break;
                            }
                            case (ErrorInterceptor.HTTP_STATUS_403): {
                                this.handleForbidden();
                                break;
                            }
                            case (ErrorInterceptor.HTTP_STATUS_503): {
                                this.handleServiceUnavailable();
                                break;
                            }
                            case (ErrorInterceptor.HTTP_STATUS_404): {
                                this.handleNotFound();
                                break;
                            }
                            default:
                                break;
                        }
                    }

                    return throwError(response);
                }));
    }

    private handleNotFound(): void {
        this.router.navigate([this.notFoundRoute]);
    }

    private handleUnauthorized(): void {
        const returnUrl: string = this.getCurrentUrl();

        if (returnUrl) {
            this.authenticationService.logout(returnUrl);

            return;
        }

        this.authenticationService.logout();
    }

    private handleForbidden(): void {
        this.router.navigate([this.forbiddenRoute]);
    }

    private handleServiceUnavailable(): void {
        this.router.navigate([this.notAllowedRoute]);
    }

    private getCurrentUrl(): string {
        const url: string = this.router.url;
        if (url.includes(AuthenticationRoute.Login) || url.includes(this.notFoundRoute) || url.includes(this.forbiddenRoute)) {
            return null;
        }

        return url;
    }
}
