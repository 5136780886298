import { Injectable } from '@angular/core';
import { NDDBreadcrumbService } from '@ndk/ng-breadcrumb';

import { IBreadcrumbHandlerSettings } from './breadcrumb-handler.model';

@Injectable()
export class BreadcrumbHandlerService {
    constructor(private breacrumbService: NDDBreadcrumbService) { }

    public addValueToCurrentRoute(settings: IBreadcrumbHandlerSettings): void {
        this.breacrumbService.setMetadata({
            id: settings.routeId,
            label: settings.label,
            sizeLimit: true,
            preserveWhitespace: true,
        });
    }
}
