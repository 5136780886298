import {
    Component,
    OnInit,
} from '@angular/core';
import { take } from 'rxjs/operators';

import { LocalStorageKey } from '../../storage/local-storage.enum';
import { LocalStorageService } from '../../storage/local-storage.service';
import { LayoutHelpMenuService } from './shared/layout-help-menu.service';

@Component({
    template: require('./layout-help-menu.content.component.html'),
    selector: 'ndd-layout-help-menu-content',
})
export class LayoutHelpMenuContentComponent implements OnInit {
    public manualUrl: string;

    constructor(
        private localStorageService: LocalStorageService,
        private helpMenuService: LayoutHelpMenuService,
    ) { }

    public ngOnInit(): void {
        const language: string = this.localStorageService.getValue(LocalStorageKey.CurrentLanguage);

        this.helpMenuService.getManualUrl(language)
            .pipe(take(1))
            .subscribe((this.handleManualUrl.bind(this)));
    }

    private handleManualUrl(url: string): void {
        this.manualUrl = url;
    }
}
