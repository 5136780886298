import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NDDTranslationModule } from 'ndd-ng-translation';

import { LayoutUserMenuContentComponent } from './layout-user-menu-content.component';
import { LayoutUserMenuHeaderComponent } from './layout-user-menu-header.component';

@NgModule({
    imports: [
        CommonModule,
        NDDTranslationModule,
    ],
    exports: [
        LayoutUserMenuHeaderComponent,
        LayoutUserMenuContentComponent,
    ],
    declarations: [
        LayoutUserMenuHeaderComponent,
        LayoutUserMenuContentComponent,
    ],
})
export class LayoutUserMenuModule { }
