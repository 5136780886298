import { HttpClient } from '@angular/common/http';
import {
    Inject,
    Injectable,
} from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NDDTranslationService } from 'ndd-ng-translation';

import {
    CORE_CONFIG_TOKEN,
    ICoreConfig,
} from 'core/core.config';
import { LoggerService } from 'core/logger/logger.service';
import {
    IAffinityAddCommand,
    IAffinityRemoveCommand,
} from './affinities.model';

@Injectable()
export class AffinitiesService {
    private apiUrl: string;

    constructor(
        @Inject(CORE_CONFIG_TOKEN) config: ICoreConfig,
        private http: HttpClient,
        private loggerService: LoggerService,
        private translation: NDDTranslationService,
    ) {
        this.apiUrl = `${ config.apiEndpoint }api/affinity`;
    }

    public addAffinity(affinity: IAffinityAddCommand): Observable<boolean> {
        return this.http.post<boolean>(`${ this.apiUrl }/add`, affinity);
    }

    public removeAffinities(command: IAffinityRemoveCommand): Observable<boolean> {
        return this.http.post<boolean>(`${ this.apiUrl }/remove`, command)
            .pipe(tap(() => this.loggerService.success(true, [this.translation.instant(command.usersDelegated.length === 1 ? '360RAffinityDeletedSuccessfully' : '360RAffinitiesDeletedSuccessfully')])));
    }
}
