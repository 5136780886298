import { Injectable } from '@angular/core';

@Injectable()
export class UrlHandlerService {
    /**
     * Define o valor associado a um parâmetro na query string.
     * Caso hajam outras correspondências ao parâmetro, seram excluídas.
     * Caso não exista, será criado um novo.
     * @param url Endereço completo para manipulação da query string.
     * @param param Nome do parâmetro.
     * @param value Valor para associar ao parâmetro.
     * @returns Endereço `url` com as alterações na query string.
     */
    public setSearchParam(url: string, param: string, value: string | number): string {
        const objUrl: URL = new URL(url);
        const searchParams: URLSearchParams = new URLSearchParams(objUrl.search);

        searchParams.set(param, value.toString());
        objUrl.search = searchParams.toString();

        return objUrl.toString();
    }
}
