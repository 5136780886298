
import {
    Component,
    OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthenticationProfileService } from '../../authentication/authentication-profile.service';
import { IAuthProfile } from '../../authentication/shared/authentication-profile.model';

@Component({
    selector: 'ndd-layout-user-menu-header',
    template: require('./layout-user-menu-header.component.html'),
})
export class LayoutUserMenuHeaderComponent implements OnDestroy {
    public domain: string;
    public logon: string;
    public email: string;
    public fullName: string;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(private authProfileService: AuthenticationProfileService) {
        this.authProfileService.onRefreshAuthProfileData$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((profile: IAuthProfile) => {
                this.domain = profile.domain;
                this.logon = profile.userName;
                this.email = profile.email;
                this.fullName = profile.fullName;
            });
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
