import {
    Inject,
    Injectable,
} from '@angular/core';

import { LocalStorageKey } from './local-storage.enum';

@Injectable()
export class LocalStorageService {

    constructor(@Inject(Window) private windowRef: Window) { }

    public setValue(key: LocalStorageKey, value: string): void {
        this.windowRef.localStorage.setItem(key.toString(), value);
    }

    public getValue(key: LocalStorageKey): string {
        return this.windowRef.localStorage.getItem(key.toString());
    }

    public deleteValue(key: LocalStorageKey): void {
        this.windowRef.localStorage.removeItem(key.toString());
    }
}
