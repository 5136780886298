import { NgModule } from '@angular/core';

import { PageReloadComponent } from './page-reload.component';

@NgModule({
    imports: [],
    exports: [PageReloadComponent],
    declarations: [PageReloadComponent],
    providers: [],
})
export class PageReloadModule { }
