import { NgModule } from '@angular/core';

import { SettingsService } from './settings.service';

@NgModule({
    providers: [
        SettingsService,
    ],
})
export class SettingsModule { }
