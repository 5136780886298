import {
    Inject,
    Injectable,
} from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';

import {
    NDDAbstractIntlService,
    NDDIntlServiceConfig,
} from 'ndd-ng-intl';

/** Classe apenas para override, usar `NDDIntlService` invés. */
@Injectable()
export class OverrideIntlService extends NDDAbstractIntlService {
    constructor(
        config: NDDIntlServiceConfig,
        private intlService: IntlService,
        @Inject(Window) windowRef: Window,
    ) {
        super(config, intlService, windowRef);
    }

    public formatNumber(value: number, format: string, culture: string = this.culture): string {
        let formattedNumber: string = this.intlService.formatNumber(value, format, culture);

        // Caso o valor contenha símbolo de moeda, formata fixando o simbolo '$' no início.
        if (formattedNumber.indexOf(this.currencySymbol(culture)) > -1) {
            const formattedCost: string = this.removeNumberSymbols(formattedNumber, this.currencySymbol(culture));

            if (value >= 0) {
                formattedNumber = '$ ' + formattedCost;
            } else {
                formattedNumber = '-$ ' + formattedCost;
            }
        }

        return formattedNumber;
    }

    private removeNumberSymbols(formattedNumber: string, symbol: string): string {
        const negativeSign: string = '-';

        return formattedNumber.replace(symbol, '').replace(negativeSign, '').trim();
    }
}
