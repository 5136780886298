/**Arquivos de localização do Angular. O Kendo exige que os arquivos de cada idioma sejam importados,
 * por conta da formatação de datas e afins.
 */
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import {
    LOCALE_ID,
    NgModule,
} from '@angular/core';
import { NDDButtonsI18nServiceConfig } from '@ndk/ng-buttons';
import { NDDDialogI18nServiceConfig } from '@ndk/ng-dialog';
import { NDDFormI18nServiceConfig } from '@ndk/ng-form';
import { NDDGridI18nServiceConfig } from '@ndk/ng-grid';
import { NDDLightGridI18nServiceConfig } from '@ndk/ng-light-grid';

import { NDDIntlServiceConfig } from 'ndd-ng-intl';
import { NDDI18nKendoTranslationGridServiceConfig } from 'ndd-ng-kendo-translation';
import { NDDTranslationConfig } from 'ndd-ng-translation';

import { NDDUploadI18nServiceConfig } from '../../shared/components/ndd-ng-upload/shared/ndd-ng-upload-i18n.service';
import { SharedModule } from '../../shared/shared.module';
import { LocalStorageKey } from '../storage/local-storage.enum';
import { I18nConfigService } from './i18n-config.service';
import {
    nddButtonsI18nServiceConfigValue,
    nddDialogI18nServiceConfigValue,
    nddFormI18nServiceConfigValue,
    nddGridI18nServiceConfigValue,
    nddIntlServiceConfigValue,
    nddKendoTranslationGridConfigValue,
    nddLightGridI18nServiceConfigValue,
    nddTranslationConfigValue,
    nddUploadI18nServiceConfigValue,
} from './i18n-resources.config';

registerLocaleData(localePt);
registerLocaleData(localeEs);
registerLocaleData(localeEn);

@NgModule({
    imports: [SharedModule],
    exports: [],
    declarations: [],
    providers: [
        I18nConfigService,
        { provide: NDDTranslationConfig, useValue: nddTranslationConfigValue },
        { provide: NDDButtonsI18nServiceConfig, useValue: nddButtonsI18nServiceConfigValue },
        { provide: NDDDialogI18nServiceConfig, useValue: nddDialogI18nServiceConfigValue },
        { provide: NDDGridI18nServiceConfig, useValue: nddGridI18nServiceConfigValue },
        { provide: NDDIntlServiceConfig, useValue: nddIntlServiceConfigValue },
        { provide: NDDFormI18nServiceConfig, useValue: nddFormI18nServiceConfigValue },
        { provide: NDDUploadI18nServiceConfig, useValue: nddUploadI18nServiceConfigValue },
        { provide: NDDLightGridI18nServiceConfig, useValue: nddLightGridI18nServiceConfigValue },
        // Kendo Translation
        { provide: NDDI18nKendoTranslationGridServiceConfig, useValue: nddKendoTranslationGridConfigValue.GRID },
        // Angular e Kendo
        {
            provide: LOCALE_ID,
            deps: [Window],
            useFactory: (windowRef: Window): string => {
                // Define a cultura atual para números e datas.
                return windowRef.localStorage.getItem(<any>LocalStorageKey.CurrentLanguage) || windowRef.navigator.language;
            },
        },
    ],
})
export class I18nModule { }
