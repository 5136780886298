import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { I18nConfigService } from './core/i18n/i18n-config.service';

@Component({
    selector: 'ndd-app',
    template: require('./app.component.html'),
})
export class AppComponent implements OnInit, OnDestroy {
    public showLoader: boolean = true;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private i18nConfigService: I18nConfigService,
    ) { }

    public ngOnInit(): void {
        // Configuração para exibir/ocultar o carregamento inicial
        this.i18nConfigService.onFirstCurrentLangChanged
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((): void => {
                // Oculta loader carregado na navegação inicial.
                this.showLoader = false;
            });
        this.i18nConfigService.subscribeLanguageChange(this.ngUnsubscribe);
        this.i18nConfigService.setLanguage();
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
